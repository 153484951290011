<template>
  <div class="trends">
    <Filters
      :loading="loading"
      :on-change="onChangeFilters"
      :use-categories="true"
      :load-on-init="!isAdmin"
      is-trends
      @filterError="onErrorFilters"
    />
    <div class="row">
      <TrendsPerTime :data="trendsTimeOtherStats" label="Share of impressions per time"/>
      <TrendsPieStats :data="trendsPieOtherStats" label="Share of impressions"/>
    </div>
    <TrendsInfoTiles :data="stats"/>
    <div class="row">
      <TrendsPerTime :data="trendsTimeStats" label="Impressions per competitors per time"/>
      <TrendsPieStats :data="trendsPieStats" label="Impressions per competitors"/>
    </div>
    <div class="row">
      <TrendsImpressions :data="retailerTrendsStats" label="IMPRESSIONS PER retailers per competitors"/>
      <TrendsImpressions :data="categoryTrendsStats" label="IMPRESSIONS PER categories per competitors"/>
    </div>
    <div class="row">
      <TrendsImpressions :data="retailerTrendsOtherStats" label="Share IMPRESSIONS PER RETAILERS"/>
      <TrendsImpressions :data="categoryTrendsOtherStats" label="Share IMPRESSIONS PER categories"/>
    </div>
  </div>
</template>

<script>
import Filters from "@/components/filters/Filters.vue";
import fetchFilters from "@/mixins/fetchFilters.js";
import ElasticAPI from "@/services/API/ElasticAPI.js";
import {showToastError} from "@/services/Helpers/HelperToast.js";
import HelperUser from "@/services/Helpers/HelperUser.js";
import {HelperColor} from "@/services/Helpers/HelperColor.js";
import store from "@/store";
import {
  calcLastVisited,
  getPeriodFromDates,
  refactoringByTimeZone,
  refactoringDate
} from "@/services/Helpers/HelperTime";

export default {
  name: "Trends",
  mixins: [fetchFilters],
  components: {
    Filters,
    TrendsImpressions: () => import('./TrendsImpressions.vue'),
    TrendsPerTime: () => import('./TrendsPerTime.vue'),
    TrendsPieStats: () => import('./TrendsPieStats.vue'),
    TrendsInfoTiles: () => import('./TrendsInfoTiles.vue'),
  },
  data() {
    return {
      isAdmin: false,
      timeTrendsStats: {
        labels: ['all'],
        datasets: [],
      },
      pieTrendStats: {
        labels: [],
        datasets: [],
      },
      retailerTrendsOtherStats: {
        labels: ['all'],
        datasets: [],
      },
      retailerTrendsStats: {
        labels: ['all'],
        datasets: [],
      },
      categoryTrendsOtherStats: {
        labels: ['all'],
        datasets: [],
      },
      categoryTrendsStats: {
        labels: ['all'],
        datasets: [],
      },
      trendsTimeStats: {
        labels: ['all'],
        datasets: [],
      },
      trendsTimeOtherStats: {
        labels: ['all'],
        datasets: [],
      },
      trendsPieStats: {
        labels: [],
        datasets: [],
      },
      trendsPieOtherStats: {
        labels: [],
        datasets: [],
      },
      stats: [
        {
          total: 0,
          share: 0,
          licenses: []
        },
        {
          total: 0,
          share: 0,
          licenses: []
        }
      ]
    }
  },
  created() {
    this.isAdmin = JSON.parse(localStorage.getItem('ttlUserInfo'))?.roles.includes('ROLE_ADMIN')
    this.loading = !this.isAdmin
  },
  methods: {
    async loadData(licenses, retailers, categories) {
      const promiseTrendsRetailers = ElasticAPI.getTrendsRetailersStats(this.filters);
      const promiseTrendsCategories = ElasticAPI.getTrendsCategoriesStats(this.filters);
      const promiseTrendsTimeStats = ElasticAPI.getTrendsTimeStats(this.filters);
      const promiseTrendsPieStats = ElasticAPI.getPieTrendsStats(this.filters)
      const promiseTrendsUsersCount = ElasticAPI.getTrendsUsersCount(this.filters)

      promiseTrendsUsersCount.then((stat) => {
        let selectedLicenses = []
        let total = 0
        let share = 0
        let sum = 0
        if (stat.aggregations?.[2]) {
          stat?.aggregations?.[2]?.buckets?.forEach(buc => {
            if (store.state.licenses?.find(license => (license.id === buc.key && licenses.ids.includes(buc.key)))?.name) {
              let licenseName = store.state.licenses.find(license => license.id === buc.key)?.name
              selectedLicenses.push(
                {
                  label: licenseName,
                  value: buc?.[3]?.value
                }
              )
              total += buc?.[3]?.value
              sum += buc?.[3]?.value
            } else {
              total += buc?.[3]?.value
            }
          })
        }
        share = total ? `${(sum / total * 100).toFixed(0)}%` : 0
        this.$set(this.stats[1], 'share', share)
        this.$set(this.stats[1], 'total', total)
        this.$set(this.stats[1], 'licenses', selectedLicenses)

      })

      promiseTrendsPieStats.then((stat) => {
        const datasets = [
          {
            label: '',
            backgroundColor: [],
            data: []
          }
        ]
        const datasetsOther = [
          {
            label: '',
            backgroundColor: [],
            data: []
          }
        ]
        const labels = []
        const labelsOther = []
        const helperColor = new HelperColor()
        if (stat?.aggregations?.[2]) {
          stat.aggregations[2].buckets.forEach((bucket, index) => {
            let licenseName
            if (store.state.licenses?.find(license => (license.id === bucket.key && licenses.ids.includes(bucket.key)))?.name) {
              licenseName = store.state.licenses?.find(license => license.id === bucket.key)?.name
              labels.push(licenseName)
              labelsOther.push(licenseName)
              datasets[0].backgroundColor.push(helperColor.getColor())
              datasets[0].data.push(bucket.doc_count)
              datasetsOther[0].backgroundColor.push(helperColor.getColor())
              datasetsOther[0].data.push(bucket.doc_count)
            } else {
              labels.push(`License-${index}`)
              datasets[0].backgroundColor.push(helperColor.getColor())
              datasets[0].data.push(bucket.doc_count)
              if (labelsOther.findIndex(lo => lo === 'Other') === -1) {
                datasetsOther[0].backgroundColor.push(helperColor.getColor())
                datasetsOther[0].data.push(bucket.doc_count)
                labelsOther.push('Other')
              } else {
                let labelsOtherIndex = labelsOther.findIndex(lo => lo === 'Other')
                datasetsOther[0].data[labelsOtherIndex] += bucket.doc_count
              }
            }
          })
        }
        let sumWithoutOther = 0
        let sum = 0
        let statsLicenses = []
        labelsOther.forEach((lo, loIndex) => {
          if (lo !== 'Other') {
            statsLicenses.push(
              {
                label: lo,
                value: datasetsOther[0].data[loIndex]
              }
            )
            sumWithoutOther += datasetsOther[0].data[loIndex]
          }
          sum += datasetsOther[0].data[loIndex]
        })

        this.$set(this.stats[0], 'share', `${(sumWithoutOther / sum * 100).toFixed(0)}%`)
        this.$set(this.stats[0], 'total', sum)
        this.$set(this.stats[0], 'licenses', statsLicenses)

        this.$set(this, 'trendsPieStats', {labels, datasets})
        this.$set(this, 'trendsPieOtherStats', {
          labels: labelsOther,
          datasets: datasetsOther
        })
      })

      promiseTrendsRetailers.then((stat) => {
        const datasetsOther = [];
        const datasets = [];
        let labelsOther = [];
        const helperColor = new HelperColor();
        retailers.ids.forEach(ret => {
          let retailer = {}
          retailer.name = store.state?.retailers?.filter(retailer => retailer.id === ret)?.[0]?.name;
          retailer.id = store.state?.retailers?.filter(retailer => retailer.id === ret)?.[0]?.id;
          labelsOther.push(retailer)
        })
        const otherObj = {
          label: 'Other',
          backgroundColor: '#3FFFDC',
          borderColor: 'transparent',
          data: []
        }
        labelsOther.forEach((l, index) => {
          otherObj.data[index] = 0
        })
        if (stat?.aggregations?.[2]) {
          let order = -1
          stat?.aggregations?.[2].buckets?.forEach((b, index) => {
            let isOther = false
            store.state?.licenses.forEach(license => {
              if (license.id === b.key && licenses.ids.includes(b.key)) {
                isOther = true
              }
            })
            if (isOther) {

              const obj = {
                label: store.state?.licenses?.filter(license => (license.id === b.key))?.[0]?.name,
                backgroundColor: helperColor.getColor(),
                borderColor: 'transparent',
                data: [],
                order: order,
                skipNull: true
              }

              labelsOther.forEach((l, index) => {
                obj.data[index] = 0
                b?.[3].buckets?.forEach(bac => {
                  if (bac.key === l.id && bac.doc_count) {
                    obj.data[index] = bac.doc_count
                  }
                })
              })
              datasets.push(obj)
              datasetsOther.push(obj)
              order--
            } else {
              const obj = {
                label: `License-${index}`,
                backgroundColor: helperColor.getColor(),
                borderColor: 'transparent',
                data: [],
                skipNull: true
              }
              labelsOther.forEach((l, index) => {
                obj.data[index] = 0
                b?.[3].buckets?.forEach(bac => {
                  if (bac.key === l.id && bac.doc_count) {
                    otherObj.data[index] += bac.doc_count
                    obj.data[index] += bac.doc_count
                  }
                })
              })
              datasets.push(obj)
            }
          })

          labelsOther.forEach((l, index) => {
            labelsOther[index] = l.name
          })
          datasetsOther.push(otherObj)
        }

        let sums = []
        labelsOther.forEach((l, index) => {
          sums[index] = {
            val: 0,
            id: index
          }
          datasetsOther.forEach(ds => {
            sums[index].val += ds.data[index]
          })
        })
        sums.sort((a, b) => (a.val > b.val) ? -1 : 1)
        let labelsOtherCopy = JSON.parse(JSON.stringify(labelsOther))
        let datasetsOtherCopy = JSON.parse(JSON.stringify(datasetsOther))
        let datasetsCopy = JSON.parse(JSON.stringify(datasets))
        sums.forEach((s, index) => {
          labelsOther[index] = labelsOtherCopy[s.id]
          datasetsOtherCopy.forEach((d, indexDs) => {
            datasetsOther[indexDs].data[index] = d.data[s.id]
          })
          datasetsCopy.forEach((d, indexDs) => {
            datasets[indexDs].data[index] = d.data[s.id]
          })
        })
        this.$set(this, 'retailerTrendsOtherStats', {
          labels: labelsOther,
          datasets: datasetsOther
        });
        this.$set(this, 'retailerTrendsStats', {
          labels: labelsOther,
          datasets: datasets
        });
      })

      promiseTrendsCategories.then((stat) => {
        const datasetsOther = [];
        const datasets = [];
        let labelsOther = [];

        const helperColor = new HelperColor();
        categories.forEach(c => {
          let category = {}
          category.name = store.state?.initialCategories?.filter(cat => cat.id === c)?.[0]?.name;
          category.id = store.state?.initialCategories?.filter(cat => cat.id === c)?.[0]?.id;
          labelsOther.push(category)
        })

        const otherObj = {
          label: 'Other',
          backgroundColor: '#3FFFDC',
          borderColor: 'transparent',
          data: []
        }
        labelsOther.forEach((l, index) => {
          otherObj.data[index] = 0
        })
        if (stat?.aggregations?.[2]) {
          let order = -1
          stat?.aggregations?.[2].buckets?.forEach((b, index) => {
            let isOther = false
            store.state?.licenses.forEach(license => {
              if (license.id === b.key && licenses.ids.includes(b.key)) {
                isOther = true
              }
            })
            if (isOther) {

              const obj = {
                label: store.state?.licenses?.filter(license => (license.id === b.key))?.[0]?.name,
                backgroundColor: helperColor.getColor(),
                borderColor: 'transparent',
                data: [],
                order: order,
                skipNull: true
              }

              labelsOther.forEach((l, index) => {
                obj.data[index] = 0
                b?.[3].buckets?.forEach(bac => {
                  if (bac.key == l.id && bac.doc_count) {
                    obj.data[index] = bac.doc_count
                  }
                })
              })
              datasets.push(obj)
              datasetsOther.push(obj)
              order--
            } else {
              const obj = {
                label: `License-${index}`,
                backgroundColor: helperColor.getColor(),
                borderColor: 'transparent',
                data: [],
                skipNull: true
              }
              labelsOther.forEach((l, index) => {
                obj.data[index] = 0
                b?.[3].buckets?.forEach(bac => {
                  if (bac.key == l.id && bac.doc_count) {
                    otherObj.data[index] += bac.doc_count
                    obj.data[index] += bac.doc_count
                  }
                })
              })
              datasets.push(obj)
            }
          })

          labelsOther.forEach((l, index) => {
            if (l.name.length > 20) {
              let textArr = l.name.split(' ')
              let middle = Math.ceil(textArr.length / 2)
              let stringArr = ['', '']
              textArr.forEach((ta, indexTa) => {
                if (indexTa === 0) {
                  stringArr[0] = stringArr[0] + `${ta}`
                } else if (indexTa < middle) {
                  stringArr[0] = stringArr[0] + ` ${ta}`
                } else {
                  stringArr[1] = stringArr[1] + ` ${ta}`
                }
              })
              l.name = `${stringArr[0]}\n${stringArr[1]}`
            }
            labelsOther[index] = l.name
          })
          datasetsOther.push(otherObj)
        }

        let sums = []
        labelsOther.forEach((l, index) => {
          sums[index] = {
            val: 0,
            id: index
          }
          datasetsOther.forEach(ds => {
            sums[index].val += ds.data[index]
          })
        })
        sums.sort((a, b) => (a.val > b.val) ? -1 : 1)
        let labelsOtherCopy = JSON.parse(JSON.stringify(labelsOther))
        let datasetsOtherCopy = JSON.parse(JSON.stringify(datasetsOther))
        let datasetsCopy = JSON.parse(JSON.stringify(datasets))
        sums.forEach((s, index) => {
          labelsOther[index] = labelsOtherCopy[s.id]
          datasetsOtherCopy.forEach((d, indexDs) => {
            datasetsOther[indexDs].data[index] = d.data[s.id]
          })
          datasetsCopy.forEach((d, indexDs) => {
            datasets[indexDs].data[index] = d.data[s.id]
          })
        })
        this.$set(this, 'categoryTrendsOtherStats', {
          labels: labelsOther,
          datasets: datasetsOther
        });
        this.$set(this, 'categoryTrendsStats', {
          labels: labelsOther,
          datasets: datasets
        });
      })

      promiseTrendsTimeStats.then((stat) => {

        let datasets = [];
        let datasetsOther = []
        const labels = [];

        const gte = this.filters.range.time_local.gte;
        const lte = this.filters.range.time_local.lte;

        const period = getPeriodFromDates(gte, lte);
        const helperColor = new HelperColor(false, '0.38');
        if (stat?.aggregations?.[2])
        stat.aggregations?.[2].buckets.forEach((bucket, index) => {
          const dateObj = refactoringByTimeZone(bucket.key_as_string, null);

          let formats = {
            "1h": "HH:mm",
            "1Y": "YYYY",
            "1M": "MM-YYYY",
          };

          let date = dateObj.format(formats?.[period] || "DD-MM-YYYY");
          labels.push(date)


          bucket[3].buckets.forEach((buc, indexBuc) => {
            if (datasets[indexBuc]) {
              let obj = datasets[indexBuc]
              obj.data[index] = buc.doc_count
            } else {
              let licenseName
              if (store?.state?.licenses.filter(b => (b.id === buc.key && licenses.ids.includes(buc.key)))[0]) {
                licenseName = store?.state?.licenses.filter(b => b.id === buc.key)[0].name
              } else {
                licenseName = `License-${indexBuc}`
              }
              let obj = {
                label: licenseName,
                data: [],
                fill: true,
                lineTension: 0.57,
                backgroundColor: helperColor.getColor(),
                borderColor: 'transparent',
              }
              obj.data[index] = buc.doc_count
              datasets[indexBuc] = obj
            }
            const helperColorOther = new HelperColor(false, '0.38');
            if (store?.state?.licenses.filter(b => (b.id === buc.key && licenses.ids.includes(buc.key)))[0]) {
              let licenseName = store?.state?.licenses.filter(b => b.id === buc.key)[0].name
              let exists = datasetsOther.findIndex(ds => {
                return ds.label === licenseName
              })
              if (exists === -1) {
                let obj = {
                  label: store?.state?.licenses.filter(b => b.id === buc.key)[0].name,
                  data: [],
                  fill: true,
                  lineTension: 0.57,
                  backgroundColor: helperColorOther.getColor(),
                  borderColor: 'transparent',
                }
                obj.data[index] = buc.doc_count
                datasetsOther.push(obj)
              } else {
                datasetsOther[exists].data[index] = buc.doc_count
              }
            } else {
              let existsOther = datasetsOther.findIndex(ds => {
                return ds.label === 'Other'
              })
              if (existsOther === -1) {
                let objOther = {
                  label: "Other",
                  data: [],
                  fill: true,
                  lineTension: 0.57,
                  backgroundColor: 'rgba(63, 255, 220, 0.38)',
                  borderColor: 'transparent',
                }
                if (objOther.data[index]) {
                  objOther.data[index] += buc.doc_count
                } else {
                  objOther.data[index] = buc.doc_count
                }
                datasetsOther.push(objOther)
              } else {
                if (datasetsOther[existsOther].data[index]) {
                  datasetsOther[existsOther].data[index] += buc.doc_count
                } else {
                  datasetsOther[existsOther].data[index] = buc.doc_count
                }
              }
            }
          })
        })

        this.$set(this, 'trendsTimeOtherStats', {
          datasets: datasetsOther,
          labels
        })
        this.$set(this, 'trendsTimeStats', {
          datasets,
          labels
        })
      })
      const promiseAll = Promise.allSettled([
        promiseTrendsRetailers,
        promiseTrendsCategories,
        promiseTrendsTimeStats,
        promiseTrendsPieStats
      ]);

      promiseAll.then(some => {
        const rejected = some.filter(result => result.status === 'rejected').map(result => result.reason)
        if (rejected.length !== 0)
          throw rejected
        this.loading = false;
      }).catch(rejected => {
        this.noData = true;
        console.error('Some api failed');
        console.error(rejected)
        showToastError('Something went wrong', this.$toast)
        this.visibleList = [];
        this.timeTrendsStats = {
          labels: ['all'],
          datasets: [],
        };
        this.loading = false;
      });

      return promiseAll
    },
    async refreshData() {
      const {licenses, retailers} = HelperUser.getUserLicensesRetailers(
        this.filters.licenses,
        this.filters.retailers
      );
      this.defaultFilters["range"] = this.filters.range;
      this.defaultFilters["contentType"] = this.filters.contentType;
      return this.loadData(licenses, retailers, this.filters.categories);
    }
  }
}
</script>

<style scoped>

</style>
